import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const Pricing = () => {
    return (
        <div className="membership-levels-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="priceing" /> 
                        Pricing
                    </span>
                    <h2>Pricing Plans</h2>
                    <p>See below our prices and features, in addition to nationwide remote support you can also get onsite support across counties - Sussex, Hampshire, Berkshire, Surrey & Kent.</p>
                </div>

                <div className="membership-levels-table table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <span className="title">List Of Features</span>
                                </th>
                                <th>
                                    <span className="price">£32</span>
                                    <span className="title">Remote Plan</span>
                                    <span className="desc">/Per Incident</span>
                                </th>
                                <th>
                                    <span className="price">P.O.A</span>
                                    <span className="title">Ad-Hoc Onsite</span>
                                    <span className="desc">/Onsite per hour</span>
                                </th>
                                <th>
                                    <span className="price">P.O.A</span>
                                    <span className="title">On Going Support Plans</span>
                                    <span className="desc">/Per Annum</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Number of features</td>
                                <td>6</td>
                                <td>7</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Remote Support
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Onsite Support
                                    </Link>
                                </td>
                                <td className="item-none"><i className='bx bx-x'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Incident Report
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Performance Check
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Sameday Service
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Nationwide
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-none"><i className='bx bx-x'></i></td>
                                <td className="item-none"><i className='bx bx-x'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Call out Charge
                                    </Link>
                                </td>
                                <td className="item-none"><i className='bx bx-x'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="#">
                                        Diagnostic Check of MacOS
                                    </Link>
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <Link to="#" className="select-btn">
                                        Get it now
                                    </Link>
                                </td>
                                <td>
                                    <Link to="#" className="select-btn">
                                        Get it now
                                    </Link>
                                </td>
                                <td>
                                    <Link to="#" className="select-btn">
                                        Get it now
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Pricing