import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Remote Support Services</h2>
                                    <p>Responding to you quickly is our priority. Available to you over a secure encrypted connection is our fast and responsive repair service of Mac Support covering the UK.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Software Fault Fixing
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Email issues
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Telephone Support
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Malware Removal
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Backup Solutions
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Mac & PC Maintainance 
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            DATA Storage Solutions
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Training & Consultancy
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Wi-Fi Solutions
                                        </li>                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Business Support</h2>
                                    <p>Getting out to your business is our priority. We offer onsite support as a tailored package, please call to disuss.</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Remote Mac Support
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Onsite Callouts
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            New User Training
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Regular Onsite Drop ins
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Data Back up and retrieval
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Virus and Malware Protection
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Restricting Macs by policy
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Application and Cloud Support
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;