import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Got a tech hiccup? We’re not just quick, we're instant.
                    </span>
                    <h2><h2>The "Can Be Fixed Now" Promise</h2>
<ul>
    <li><strong>Immediate Resolutions:</strong> <p>Why wait? We sort issues in the here and now.</p></li>
</ul>

<h2>Why You'll Love Us</h2>
<ul>
    <li><strong>Responsive:</strong> <p>Your business doesn't wait. Neither do we.</p></li>
    <li><strong>Remote:</strong> <p>Whether you're in East Sussex or nationwide, we're always local to you.</p></li>
    <li><strong>Friendly:</strong><p> Experience service with a smile, from helpline to solution.</p></li>
    <li><strong>Experienced:</strong> <p>We have vast expertise across:</p>
        <ul>
            <li>Mac</li>
            <li>Windows</li>
            <li>Google Workspace</li>
            <li>Microsoft365</li>
            <li>Cyber Security</li>
        </ul>
    </li>
</ul>

<h2>Comprehensive IT Care for Every Need</h2>
<ul>
    <li><strong>Unified Support:</strong> Seamless solutions for Mac, Windows, and all in-between.</li>
    <li><strong>Integrated Tools:</strong> Harness the power of Google Workspace & Microsoft365 under our guidance.</li>
    <li><strong>Cyber Security:</strong> A robust defense against modern threats, ensuring your digital assets remain safe.</li>
</ul>

<h2>Embrace the Future of Tech Support</h2>
<ul>
    <li><strong>Beyond Troubleshooting:</strong> We aim to enhance your entire digital experience.</li>
    <li><strong>Solution-Focused Approach:</strong> We prioritize solutions, not just identifying problems.</li>
</ul>

 </h2>
                    <p>
💼 Speed. Solutions. Success. Revolutionize your IT journey with us! 🚀</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Eliminate Duplicate Files
                                </Link>
                            </h3>
                            <p>Consilidate duplicate files stored in different folders and free up storage space whilst improving file organisation. </p>

                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    View Service Offering in More Detail
                                </Link>
                            </h3>

                            <p>.</p>
                            
                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Know what you are paying upfront. No Hidden Costs.
                                </Link>
                            </h3> 
                            
                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;